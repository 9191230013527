<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Generar guía de remisión</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
        <!--Client-->
        <div>
          <vs-input @blur="document.client.businessName= trimString(document.client.businessName)" label="Razón Social*"
                    v-model="document.client.businessName"
                    class="w-full mt-5" name="businessName"
                    v-validate="'required'" disabled/>
          <span class="text-danger text-sm"
                v-show="errors.has('businessName')">{{ errors.first('businessName') }}</span>
        </div>
        <!--End-->
        <!--Type Of document-->
        <div>
          <vs-input
            label="Tipo de documento*"
            v-model="document.client.typeOfDocument"
            class="w-full mt-5" name="typeOfDocument"
            v-validate="'required'" disabled/>
          <span class="text-danger text-sm"
                v-show="errors.has('typeOfDocument')">{{ errors.first('typeOfDocument') }}</span>
        </div>
        <!--End-->
        <!--Document-->
        <div>
          <vs-input
            label="Nro. de documento*"
            v-model="document.client.documentNumber"
            class="w-full mt-5" name="documentNumber"
            v-validate="'required'" disabled/>
          <span class="text-danger text-sm"
                v-show="errors.has('documentNumber')">{{ errors.first('documentNumber') }}</span>
        </div>
        <!--End-->
        <!--Address-->
        <div class="mt-5">
          <vs-input @blur="document.address= trimString(document.address)" label="Dirección*"
                    v-model="document.address"
                    class="w-full mt-5" name="address"
                    v-validate="'required'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('address')">{{ errors.first('address') }}</span>
        </div>

        <div class="vx-row mb-4">
          <!-- Departments -->
          <div class="mt-5 w-1/4 vx-col">
            <label class="text-sm opacity-75">Departamento*</label>
            <v-select placeholder="Selecciona" v-validate="'required'" @input="document.province = {}"
                      label="nomDepartamento"
                      :options="departments" :clearable="false"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="document.department" class="mb-4 md:mb-0" name="department"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('department')">{{ errors.first('department') }}</span>
          </div>
          <!--End--->

          <!-- Provinces -->
          <div class="mt-5 w-1/4 vx-col">
            <label class="text-sm opacity-75">Provincia*</label>
            <v-select placeholder="Selecciona" v-validate="'required'" @input="document.district = {}"
                      label="nomProvincia"
                      :options="filteredProvince" :clearable="false"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="document.province" class="mb-4 md:mb-0" name="province"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('province')">{{ errors.first('province') }}</span>
          </div>
          <!--End--->

          <!-- Districts -->
          <div class="mt-5 w-1/4 vx-col">
            <label class="text-sm opacity-75">Distrito*</label>
            <v-select placeholder="Selecciona" v-validate="'required'" @input="getUbigeo" label="nomDistrito"
                      :options="filteredDistrict"
                      :clearable="false"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="document.district" class="mb-4 md:mb-0" name="district"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('district')">{{ errors.first('district') }}</span>
          </div>
          <!--End--->

          <!--Ubigeo-->
          <div class="w-1/4 vx-col">
            <vs-input @blur="document.ubigeo= trimString(document.ubigeo)" label="Ubigeo*"
                      v-model="document.ubigeo"
                      class="w-full mt-5" name="ubigeo"
                      v-validate="'required'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('ubigeo')">{{ errors.first('ubigeo') }}</span>
          </div>
          <!---End-->
        </div>
        <!--End-->

        <!--Carrier--->
        <div class="vx-row mb-4">
          <div class="vx-col w-1/2">
            <label class="text-sm opacity-75">Tipo de Documento de transportista*</label>
            <v-select :options="['DNI']"
                      :clearable="false"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="document.carrierDocumentType" class="mb-4 md:mb-0" name="warehouseOut"
                      v-validate="'required'"/>

            <span class="text-danger text-sm"
                  v-show="errors.has('carrierDocumentType')">{{ errors.first('carrierDocumentType') }}</span>
          </div>
          <div class="vx-col w-1/2">
            <vs-input
              label="Nro. de documento de transportista*"
              v-model="document.carrierDocumentNumber"
              class="w-full" name="carrierDocumentNumber"
              v-validate="'required'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('carrierDocumentNumber')">{{ errors.first('carrierDocumentNumber') }}</span>
          </div>
        </div>

        <div class="vx-row mb-4">
          <div class="vx-col w-1/4">
            <vs-input
              label="Nombre de transportista*"
              v-model="document.carrierFirstName"
              class="w-full" name="carrierFirstName"
              v-validate="'required'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('carrierFirstName')">{{ errors.first('carrierFirstName') }}</span>
          </div>
          <div class="vx-col w-1/4">
            <vs-input
              label="Apellido de transportista*"
              v-model="document.carrierLastName"
              class="w-full" name="carrierLastName"
              v-validate="'required'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('carrierLastName')">{{ errors.first('carrierLastName') }}</span>
          </div>
          <div class="vx-col w-1/4">
            <vs-input
              label="Licencia de transportista*"
              v-model="document.carrierDriverLicense"
              class="w-full" name="carrierDriverLicense"
              v-validate="'required'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('carrierDriverLicense')">{{ errors.first('carrierDriverLicense') }}</span>
          </div>
          <div class="vx-col w-1/4">
            <vs-input
              label="Placa de transportista*"
              v-model="document.carrierPlate"
              class="w-full" name="carrierPlate"
              v-validate="'required'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('carrierPlate')">{{ errors.first('carrierPlate') }}</span>
          </div>
        </div>
        <!--End-->

        <!--Products-->
        <template>
          <vs-table class="mt-5" style="padding-bottom: 14rem" :data="document.detail">

            <template slot="thead">
              <vs-th>Nombre</vs-th>
              <vs-th>SKU</vs-th>
              <vs-th>Cantidad</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                <vs-td :data="tr.name">
                  <p>{{ tr.name }}</p>
                </vs-td>

                <vs-td :data="tr.sku">
                  <p> {{ tr.sku }}</p>
                </vs-td>

                <vs-td :data="tr.quantity">
                  <p>{{ tr.quantity }}</p>
                </vs-td>

              </vs-tr>
            </template>
          </vs-table>
          <!---->
        </template>
        <!--End-->
      </div>
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Guardar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cerrar</vs-button>
      <div v-if="progress" style="margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import trimString from '../../mixins/trimString'

import departments from '../../data/ubigeo/departments.json'
import provinces from '../../data/ubigeo/province.json'
import districts from '../../data/ubigeo/district.json'
import ubigeo from '../../data/ubigeo/ubigeo.json'

import { db } from '@/firebase/firebaseConfig'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar
  },
  mixins: [trimString],
  data () {
    return {
      document: {
        detail: [],
        department: null,
        province: null,
        district: null
      },
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      progress: false,
      departments: departments,
      provinces: provinces,
      districts: districts,
      ubigeo: ubigeo
    }
  },
  watch: {
    isSidebarActive (val) {
      if (val) {
        this.document = {
          department: null,
          province: null,
          district: null
        }
        this.document.id = this.data.id
        this.document.detail = this.data.detail
        this.document.client = {
          typeOfDocument: this.data.client.documentIdentityType,
          businessName: this.data.client.businessName,
          documentNumber: this.data.client.documentNumber
        }
        this.document.local = this.data.company.fiscalAddress
        this.document.invoiceType = this.data.invoiceType
        this.document.serial = this.data.serial
        this.document.sequence = this.data.sequence
      }
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      },
      document: {
        handle (val) {
          console.log(val.province)
        },
        deep: true
      }
    },
    isFormValid () {
      return !this.errors.any()
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    },
    filteredProvince () {
      if (this.document.department) {
        return this.provinces.filter((province) => {
          return province.codDepartamento === this.document.department.codDepartamento
        }).sort((a, b) => {
          a.nomProvincia.localeCompare(b.nomProvincia)
        })
      }
      else {
        return []
      }
    },
    filteredDistrict () {
      console.log("Entra aquí", this.document.province)
      if (this.document.province) {
        return this.districts.filter((district) => {
          return district.codProvincia === this.document.province.codProvincia
        }).sort((a, b) => {
          a.nomDistrito.localeCompare(b.nomDistrito)
        })
      }
      else {
        return []
      }
    }
  },
  methods: {
    /**
     * Generate referral guide
     */
    async submitData () {
      const result = await this.$validator.validateAll()
      if (result) {
        try {
          this.progress = true
          // Send Nubefact
          const response = await this.sendNubefact()

          setTimeout(async () => {
            await this.verifyGuide(response.nubefact)
            this.progress = false
          }, 7000)

          // End
        } catch (e) {
          console.error(e)
          this.progress = false
        }
      }
    },
    /**
     * Seng guide tu nubefact
     */
    async sendNubefact () {
      const items = []
      this.document.detail.forEach((p) => {
        const obj = {
          'unidad_de_medida': 'NIU',
          'codigo': p.sku,
          'descripcion': p.name,
          'cantidad': p.quantity
        }
        items.push(obj)
      })
      const document = {
        'operacion': 'generar_guia',
        'tipo_de_comprobante': 7,
        'serie': 'TTT1',
        'cliente_tipo_de_documento': this.document.client.typeOfDocument == 'RUC' ? 6 : 1,
        'cliente_numero_de_documento': this.document.client.documentNumber,
        'cliente_denominacion': this.document.client.businessName,
        'cliente_direccion': this.document.address,
        'cliente_email': '',
        'cliente_email_1': '',
        'cliente_email_2': '',
        'fecha_de_emision': new Date().getDate() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getFullYear(),
        'observaciones': 'Venta',
        'motivo_de_traslado': '01',
        'peso_bruto_total': '1',
        "peso_bruto_unidad_de_medida": "KGM",
        'numero_de_bultos': items.length,
        'tipo_de_transporte': '02',
        'fecha_de_inicio_de_traslado': new Date().getDate() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getFullYear(),

        'conductor_documento_tipo': 1,
        'conductor_documento_numero': this.document.carrierDocumentNumber,
        'conductor_nombre': this.document.carrierFirstName,
        'conductor_apellidos': this.document.carrierLastName,
        'conductor_numero_licencia': this.document.carrierDriverLicense,
        'transportista_placa_numero': this.document.carrierPlate,

        'punto_de_partida_ubigeo': '150101',
        'punto_de_partida_direccion': this.document.local,
        'punto_de_llegada_ubigeo': this.document.ubigeo,
        'punto_de_llegada_direccion': this.document.address,
        'enviar_automaticamente_a_la_sunat': false,
        'enviar_automaticamente_al_cliente': false,
        'codigo_unico': '',
        'formato_de_pdf': ''
      }

      const response = await this.$http.post('https://southamerica-east1-imvialex.cloudfunctions.net/nubefact-sendReferenceGuides', {
        document,
        items
      }, {
        headers: {
          'Authorization': 'Bearer bd14b377-92f6-4741-8303-1f372131897c'
        }
      })
      return response.data
    },
    /**
     * Verify guide
     * @param tr
     */
    async verifyGuide (tr) {
      console.log(tr)
      if (!tr.aceptada_por_sunat) {
        const response = await this.$http.post('https://southamerica-east1-imvialex.cloudfunctions.net/nubefact-verifyGuide', {
          document: {
            'operacion': 'consultar_guia',
            'tipo_de_comprobante': 7,
            'serie': tr.serie,
            'numero': tr.numero
          }
        }, {
          headers: {
            Authorization: 'Bearer bd14b377-92f6-4741-8303-1f372131897c'
          }
        })

        /// Update in firestore
        await db.collection('sales').doc(this.document.id).update({
          nubefact: {
            ...response.data.nubefact
          },
          accepted: response.data.nubefact.aceptada_por_sunat
        })
        // End

        if (response.data.nubefact.aceptada_por_sunat) {
          // Open PDF in browser
          window.open(response.data.nubefact.enlace_del_pdf, 'blank')
          // End
          // Update row
          tr.nubefact = {
            ...response.data.nubefact
          }
          tr.accepted = true
          // End

          this.$vs.notify({
            color: 'success',
            title: 'Guía de remisión',
            text: 'Guía aceptada por Sunat',
            time: 5000
          })
        }
        else {
          this.$vs.notify({
            color: 'warning',
            title: 'Guía de remisión',
            text: 'Guía no aceptada por Sunat',
            time: 5000
          })
        }
      }
    },
    /**
     * Get ubigeo
     */
    getUbigeo () {
      console.log(this.document.province)
      if (this.document.district) {
        this.ubigeo.forEach((u) => {
          if (u.districts.toUpperCase() === this.document.district.nomDistrito.toUpperCase() && u.province.toUpperCase() === this.document.province.nomProvincia.toUpperCase() && u.department.toUpperCase() === this.document.department.nomDepartamento.toUpperCase()) {
            this.document.ubigeo = u.ubigeo
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52004;
  }

  ::v-deep .vs-sidebar {
    z-index: 52004;
    width: 1000px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
